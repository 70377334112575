<template>
  <!-- the style tag avoids Flash of unstyled content -->
  <div
    class="font-body fade-in announcement-bar-root"
    style="opacity: var(--ab-opacity-visible, 0)"
  >
    <component
      v-for="(bar, index) in filteredBars"
      :key="index"
      :is="map[bar.type]"
      v-bind="getProps(bar, index)"
    ></component>
  </div>
</template>

<script>
import BarsConfig from "../configs/bars.config";
import Pages from "../configs/pages.config";

import getPlatform from "./../utils/user-agent.util";

import AnnouncementBar from "./announcement-bar/announcement-bar";
import CountDownTimerBar from "./countdowntimer-bar/countdowntimer-bar";
import ContactFormBar from "./contactform-bar/contactform-bar";
import FreeShippingBar from "./freeshipping-bar/freeshipping-bar";
import SocialShareBar from "./socialshare-bar/socialshare-bar";
import RotatingBar from "./rotating-bar/rotating-bar";

export default {
  name: "RenderBars",
  components: {
    AnnouncementBar,
    CountDownTimerBar,
    ContactFormBar,
    FreeShippingBar,
    SocialShareBar,
    RotatingBar,
  },
  props: {
    bars: {
      type: Array,
    },
  },
  data() {
    return {
      map: BarsConfig,
      filteredBars: [],
    };
  },
  watch: {
    $route() {
      this.setFilteredBars();
    },
    bars() {
      this.setFilteredBars();
    },
  },
  mounted() {
    this.setFilteredBars();
  },
  computed: {},
  methods: {
    getProps: function (bar, index) {
      return {
        value: bar,
        index: index,
      };
    },
    setFilteredBars: function () {
      this.filteredBars = this.bars?.filter((bar) => this.showBar(bar));
      return;
    },
    showBar: function (bar) {
      if (
        !window.FPI ||
        !window.FPI.utility ||
        !window.FPI.utility.convertUrlToAction
      ) {
        // preview mode
        return bar;
      }

      // Alternate solution can use window?.__INITIAL_STATE__?.route?.meta?.available_page_slug
      const pageAction = window.FPI.utility.convertUrlToAction(
        window.location.pathname
      );

      const showPages = bar?.configs?.targetPageAndPlatform?.page?.map(
        (page) => {
          if (Pages[page.page_type]) {
            return Pages[page.page_type];
          }
          return page.page_type;
        }
      );

      const targetPlatforms = bar?.configs?.targetPageAndPlatform?.platform;
      const platformString =
        targetPlatforms && targetPlatforms.length
          ? targetPlatforms.join("_")
          : "";
      const currentPlatform = getPlatform();
      const isAllowed = platformString
        ? platformString.indexOf(currentPlatform) !== -1
        : true;
      const pageType =
        pageAction?.page?.type === "sections"
          ? pageAction?.page?.params?.group?.[0]
          : pageAction?.page?.type;

      return showPages?.includes(pageType) && isAllowed;
    },
  },
};
</script>

<style scoped lang="scss"></style>
